 @font-face {
   font-family: 'Exo-Bold';
   src: url('../fonts/Exo-Bold.eot?#iefix') format('embedded-opentype'),
   url('../fonts/Exo-Bold.woff') format('woff'),
   url('../fonts/Exo-Bold.ttf')  format('truetype'),
   url('../fonts/Exo-Bold.svg#FiraSans-Light') format('svg');
   font-weight: normal;
   font-style: normal;
 }
 @font-face {
    font-family: 'Exo-Regular';
    src: url('../fonts/Exo-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo-Regular.woff') format('woff'),
    url('../fonts/Exo-Regular.ttf')  format('truetype'),
    url('../fonts/Exo-Regular.svg#FiraSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
    font-family: 'Exo-LightItalic';
    src: url('../fonts/Exo-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo-LightItalic.woff') format('woff'),
    url('../fonts/Exo-LightItalic.ttf')  format('truetype'),
    url('../fonts/Exo-LightItalic.svg#FiraSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
    font-family: 'Exo-Light';
    src: url('../fonts/Exo-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo-Light.woff') format('woff'),
    url('../fonts/Exo-Light.ttf')  format('truetype'),
    url('../fonts/Exo-Light.svg#FiraSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
    font-family: 'Exo-Medium';
    src: url('../fonts/Exo-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo-Medium.woff') format('woff'),
    url('../fonts/Exo-Medium.ttf')  format('truetype'),
    url('../fonts/Exo-Medium.svg#FiraSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
   font-family: 'Roboto-Light';
   src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
   url('../fonts/Roboto-Light.woff') format('woff'),
   url('../fonts/Roboto-Light.ttf')  format('truetype'),
   url('../fonts/Roboto-Light.svg#FiraSans-Light') format('svg');
   font-weight: normal;
   font-style: normal;
 }
