.header{
  padding: 20px 0 25px;
  & .container{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &-logo{
    display: flex;
    align-items: center;
    &__left{
      font-size: 26px;
      font-family: 'Exo-Bold';
      line-height: 29px;
      color: $blue;
      margin-right: 20px;
      @include tablets{
        font-size: 18px;
      }
    }
    &__right{
      font-size: 15px;
      line-height: 17px;
      color: $black;
      font-family: 'Exo-Light';
      @include tablets{
        font-size: 14px;
      }
    }
  }
  &-burger{
    margin-left: 90px;
    display: none;
    @include laptop{
      display: block;
    }
    @include tablets{
      margin-left: 0;
    }
  }
  &-nav{
    display: flex;
    flex: 0 1 580px;
    justify-content: space-between;
    @include laptop{
      display: none;
      flex-direction: column;
      position: absolute;
      top: 83px;
      right: -3%;
      width: 300px;
      height: 100vh;
      background: #e4e8ea;
      align-items: flex-start;
      justify-content: flex-start;
      z-index: 10;
      &.active{
        display: flex;
      }
    }
    &-item{
      font-family: 'Exo-Bold';
      font-size: 16px;
      color: $black;
      @include laptop{
        padding: 20px 0;
        text-transform: uppercase;
        border-bottom: 2px solid #c8d0d4;
        width: 85%;
        margin: 0 auto;
        box-sizing:border-box;
      }
    }
  }
  &-contacts{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include laptop{
      margin-left: auto;
    }
    @include tablets{
      display: none;
    }
    &__phone{
      font-size: 26px;
      text-transform: uppercase;
      font-family: 'Exo-Bold';
      color: $black;
    }
    &__callback{
      font-family: 'Exo-Medium';
      font-size: 20px;
      border-bottom: 1px solid $blueSmall;
      color: $blueSmall;
    }
  }
}
.main{
  padding-top: 95px;
  padding-bottom: 110px;
  background: url(../images/main-bg.jpg) center center no-repeat;
  background-size: cover;
  @include laptop{
    padding-bottom: 45px;
  }
  @include tablets{
    padding-top: 55px;
  }
  &__title{
    color: #fff;
    font-size: 66px;
    font-family: 'Exo-Bold';
    margin-bottom: 45px;
    @include laptop{
      font-size: 48px;
    }
    @include tablets{
      font-size: 26px;
      margin-bottom: 35px;
    }
    & span{
      color:$nebo;
    }
  }
  &-content{
    display: flex;
    justify-content: space-between;
    @include laptop{
      flex-wrap: wrap;
      position: relative;
    }
    &-left{
      flex: 0 1 700px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__title{
        font-size: 32px;
        line-height: 44px;
        font-family: 'Roboto-Light';
        color:$nebo;
        @include laptop{
          font-size: 26px;
          margin-bottom: 50px;
          & br{
            display: none;
          }
        }
        @include tablets{
          font-size: 18px;
          margin-bottom: 40px;
          line-height: 27px;
        }
      }
      &__footer{
        font-family: 'Exo-LightItalic';
        font-size: 22px;
        line-height: 36px;
        color: $orange;
        @include laptop{
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 18px;
        }
        @include tablets{
          display: none;
        }
      }
    }
    &-right{
      max-width: 360px;
      padding: 50px;
      background: #fff;
      @include laptop{
        margin-left: auto;
      }
      @include tablets{
        padding: 50px 20px;
      }
    }
  }
  &-list{
    padding-top: 70px;
    & .container{
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      border-bottom: 2px solid #dddddd;
      @include laptop{
        flex-wrap: wrap;
      }
      @include tablets{
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    &-item{
      @include laptop{
        flex:0 1 33%;
        padding-bottom: 80px;
      }
      @include phones{
        flex: 0 1 100%;
        padding-bottom: 50px;
      }
      &__img{
        max-height: 50px;
        margin-bottom: 30px;
      }
      &__text{
        font-size: 18px;
        font-family: 'Roboto-Light';
        color: #232323;
        @include phones{
          & br{
            display: none;
          }
        }
      }
    }
  }
}
.usl{
  &-list{
    margin-bottom: 130px;
    padding-top: 90px;
    @include tablets{
      padding-top: 0;
      margin-bottom: 80;
    }
    & .container{
      display: flex;
      align-items: center;
      @include tablets{
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }
    &-left{
      margin-right: 60px;
      @include tablets{
        margin-right: 0;
        &__img{
          max-width: 100%;
        }
      }
    }
    &-right{
      flex: 1;
      &__title{
        font-size: 48px;
        font-family: 'Exo-Bold';
        line-height: 60px;
        color: #232323;
        border-bottom: 2px solid #ececec;
        padding-bottom: 35px;
        @include laptop{
          font-size: 32px;
          & br{
            display: none;
          }
        }
        @include tablets{
          font-size: 26px;
          line-height: 33px;
          border-bottom:  none;
          padding-bottom: 0;
        }
      }
    }
    &-main{
      display: flex;
      margin-top: 30px;
      @include laptop{
        flex-direction: column;
      }
      }
    @include tablets{

    }
    &-item{
      border-right: 2px solid #ececec;
      flex: 0 1 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include laptop{
          border-right: none;
      }
      @include tablets{
        align-items: flex-start;
        margin-bottom: 30px;
      }
      &:last-child{
        border-right: none;
      }
      &__title{
        font-family: 'Exo-Bold';
        font-size: 32px;
        line-height: 65px;
        color: $orange;
        @include tablets{
          font-size: 20px;
          line-height: 27px;
        }
      }
      &__text{
        font-size: 20px;
        font-family: 'Roboto-Light';
        color: #333;
      }
    }
  }
}
.sluh-list{
  position: relative;
  &:before{
    position: absolute;
    width: 90%;
    height: 100%;
    top: 0;
    left: 10%;
    background: #e4e8ea;
    display: block;
    content: '';
    @include tablets{
      width: 100%;
      left: 0;
    }
  }
  &__title{
    padding: 120px 0 90px;
    font-size: 58px;
    line-height: 65px;
    font-family: 'Exo-Bold';
    color: #232323;
    @include laptop{
      font-size: 48px;
      max-width: 80%;
      margin-left: auto;
    }
    @include tablets{
      font-size: 26px;
      max-width: 100%;
      line-height: 33px;
      padding: 85px 0 55px;
      & br{
        display: none;
      }
    }
  }
  &-main{
    display: flex;
    justify-content: space-between;
    padding-bottom: 110px;
    max-width: 80%;
    margin-left: auto;
    @include laptop{
      flex-direction: column;
      padding-bottom: 25px;
    }
  }
  &-item{
    display: flex;
    align-items: flex-start;
    @include laptop{
      margin-bottom: 60px;
      &:first-child{
        & .sluh-list-item__number{
          margin-right:40px;
        }
      }
    }
    &__number{
      font-size: 57px;
      color: #fff;
      font-family: 'Exo-Bold';
      line-height: 43px;
      margin-right: 30px;
    }
    &-desc{
      &__title{
        font-size: 26px;
        color: #333333;
        font-family: 'Exo-Bold';
        margin-bottom: 25px;
      }
      &__text{
        font-size: 18px;
        font-family: 'Roboto-Light';

      }
    }
  }
}
.svg-slider{
  padding: 170px 0;
  @include laptop{
    padding: 100px 0;
  }
  @include tablets{
    padding: 65px 0 20px;
  }
  &-main{
    display: flex;
    @include laptop{
      flex-wrap: wrap;
    }
  }
  &-left{
    flex: 0 1 570px;
    @include laptop{
      flex: 0 1 100%;
    }
    &-item{
      margin-bottom: 60px;
      &:last-child{
        margin-bottom: 0;
      }
    }

    &__title{
      font-family: 'Exo-Light';
      font-size: 36px;
      color: #404b6e;
      margin-bottom: 50px;
      @include tablets{
        font-size: 26px;
        margin-bottom: 35px;
      }
    }
    &__text{
      color: #333333;
      font-family: 'Roboto-Light';
    }
  }
  &-right{
    width: 100%;
    max-width: 570px;
    position: relative;
    &-list{
      width: 300px;
      height: 300px;
      position: absolute;
      border-radius: 50%;
      //overflow: hidden;
      top: 49%;
      left: 54%;
      transform: translateX(-50%) translateY(-50%);
      @include tablets{
        width: 190px;
        height: 190px;
      }
    }
    &__item{
      width: 100%;
      height: 100%;
      background-size: cover!important;
      display: none;
      border-radius: 50%;
      &.active{
        display: block;
      }
    }
  }
  &-bot{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    @include laptop{
      flex-wrap: wrap;
    }
    &__item{
      flex: 0 1 270px;
      border-top: 3px solid #d63838;
      padding-top: 25px;
      font-family: 'Roboto-Light';
      font-size: 18px;
      @include laptop{
        margin-bottom: 60px;
        flex: 0 1 45%;
      }
      @include tablets{
        flex: 0 1 100%;
      }
    }
  }
}
.shag{
  &__title{
    font-family: 'Exo-Bold';
    font-size: 58px;
    color: #232323;
    margin-bottom: 60px;
    @include laptop{
      font-size: 48px;
    }
    @include laptop{
      font-size: 26px;
      margin-bottom: 35px;
    }
  }
  &__text{
    font-size: 24px;
    color: #232323;
    font-family: 'Roboto-Light';
    margin-bottom: 110px;
    @include laptop{
      margin-bottom: 0;
    }
    @include tablets{
      font-size: 20px;
    }
  }
  &-main{
    &-item{
      display: flex;
      justify-content: space-between;
      margin-bottom: 90px;
      &:last-child{
        //margin-bottom: 0;
      }
      @include laptop{
        flex-direction: column-reverse;
      }
      @include tablets{
        margin-bottom: 60px;
      }
      &--nechet{
        flex-direction: row-reverse;
        @include laptop{
          flex-direction: column-reverse;
        }
        & .shag-main-item-image{
          background: url(../images/shag2.png) center center no-repeat;
        }
      }
      &-image{
        max-width: 570px;
        width: 100%;
        display: block;
        background: url(../images/shag1.png) center center no-repeat;
        background-size: contain;
        @include laptop{
          height: 670px;
          margin: 0 auto;
        }
        @include tablets{
          background-size: cover;
        }
      }
      &-desc{
        max-width: 440px;
        @include laptop{
          max-width: 100%;
          & br{
            display: none;
          }
        }
        @include tablets{
          z-index: 2;
          position: relative;
        }
      }
      &-top{
        position: relative;
        margin-bottom: 90px;
        @include tablets{
          margin-bottom: 35px;
        }
        &__title{
          font-size: 36px;
          font-family: 'Exo-Light';
          color: #404b6e;
          @include tablets{
            font-size: 26px;
          }
        }
        &__number{
          position: absolute;
          font-family: 'Exo-Bold';
          font-size: 138px;
          line-height: 60px;
          color: #e4e8ea;
          z-index: -1;
          top: -20px;
          @include tablets{
            font-size: 70px;
          }
        }
      }
      &-list{
        &-it{
          margin-bottom: 70px;
          font-family: 'Exo-Light';
          font-size: 22px;
          line-height: 33px;
          color: #333333;
          @include tablets{
            margin-bottom: 35px;
          }
          &__title{
            font-family: 'Exo-Bold';
          }
        }
      }
    }
  }
  &-footer{
    display: flex;
    align-items: center;
    padding-bottom: 140px;
    @include tablets{
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 50px;
    }
    &__img{
      margin-right: 70px;
      @include tablets{
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
    &__text{
      font-size: 32px;
      font-family: 'Exo-Light';
      color: #232323;
      & span{
        font-family: 'Exo-Bold';
      }
      @include tablets{
        font-size: 26px;
      }
    }
  }
}
.resh{
  background: url('../images/resh-bg.jpg') top center no-repeat;
  padding: 130px 0 110px;
  @include tablets{
    padding: 85px 0 0;
  }
  &__title{
    font-size: 58px;
    color: #fff;
    font-family: 'Exo-Bold';
    margin-bottom: 260px;
    @include laptop{
      font-size: 48px;
    }
    @include tablets{
      font-size: 26px;
    }
  }
  &-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include laptop{
      flex-direction: column;
    }
  }
  &-list{
    &-left{
      max-width: 440px;
      @include laptop{
        margin-bottom: 65px;
      }
    }
    &-right{
      width: 100%;
      max-width: 570px;
      background: $orange;
      padding: 70px 50px;
      box-sizing: border-box;
      @include tablets{
        padding: 50px 20px;
      }
      &__title{
        font-size: 26px;
        font-family: 'Exo-Bold';
        color: #fff;
        margin-bottom: 25px;
      }
      &__text{
        font-size: 22px;
        color: #fff;
        line-height: 32px;
        font-family: 'Roboto-Light';
        margin-bottom: 55px;
      }
      &__but{
        height: 86px;
        font-family: 'Exo-Bold';
        font-size: 26px;
        color: #22262b;
        background: #f2f2f2;
        border: none;
        border-bottom: 4px solid #d3d3d3;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 350px;
        margin: 0 auto;
        @include tablets{
          font-size: 20px;
        }
      }
    }
  }
  &-left{
    &-item{
      color: #fff;
      font-size: 22px;
      margin-bottom: 90px;
      @include tablets{
        margin-bottom: 50px;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &__title{
        font-family: 'Exo-Bold';
        & span{
          color: $orange;
        }
      }
      &__text{
        font-family: 'Exo-Light';
      }
    }
  }
}
.pred{
  padding: 120px 0 0;
  @include laptop{
    padding: 0;
  }
  &__title{
    font-size: 58px;
    font-family: 'Exo-Bold';
    color: #232323;
    margin-bottom: 80px;
    @include laptop{
      font-size: 48px;
    }
    @include tablets{
      font-size: 26px;
      margin: 60px 0 20px;
    }
  }
  &-top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    @include laptop{
      flex-wrap: wrap;
    }
    &-item{
      flex: 0 1 265px;
      color: #333333;
      font-size: 20px;
      line-height: 30px;
      @include laptop{
        margin-bottom: 60px;
      }
      @include tablets{
        margin-bottom: 30px;
      }
      &__img{
        max-height: 55px;
        margin-bottom: 50px;
        @include laptop{
          margin-bottom: 15px;
        }
      }
      &__title{
        font-family: 'Exo-Bold';
      }
      &__text{
        font-family: 'Exo-Light';
      }
    }
  }
  &-main{
    display: flex;
    align-items: center;
    @include laptop{
      flex-direction: column;
    }
    &-left{
      flex: 0 1 50%;
      display: flex;
      justify-content: flex-end;
      @include laptop{
        flex: auto;
      }
      &-inn{
        max-width: 530px;
      }
    }
    &__title{
      font-size: 32px;
      color: #404b6e;
      font-family: 'Exo-Light';
      margin-bottom: 60px;
      @include tablets{
        font-size: 26px;
        margin-bottom: 30px;
      }
    }
    &__text{
      font-size: 22px;
      color: #333333;
      font-family: 'Roboto-Light';
      margin-bottom: 100px;
      @include tablets{
        font-size: 20px;
        margin-bottom: 60px;
      }
    }
    &-right{
      flex: 0 1 50%;
      position: relative;
      @include tablets{
        display: none;
      }
      &__img{
        width: 100%;
      }
      &-but{
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5a83e;
        color: #fff;
        border-radius: 50%;
        font-family: 'Exo-Light';
        font-size: 30px;
        line-height: 20px;
        position: absolute;
        & span{
          margin-top: -7px;
        }
        &--first{
          left: 12%;
          top: 50%;
          transform: translateY(-50%);
        }
        &--second{
          left: 49%;
          bottom: 2%;
          transform: translateX(-50%);
        }
      }
      &-list{
        position: absolute;
        width: 300px;
        height: 300px;
        box-sizing: border-box;
        content: '';
        background: #fff;
        border-radius: 50%;
        border: 5px solid #fff;
        top: -15%;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        &__item{
          &.active{
            display: block;
          }
          display: none;
          content: '';
          width: 100%;
          height: 300px;
          background-size: cover!important;
        }
      }
    }
    &-list{
      &-item{
        display: flex;
        align-items: flex-start;
        margin-bottom: 105px;
        @include tablets{
          margin-bottom: 50px;
        }
        &__img{
          margin-right: 35px;
        }
        &__desc{
          font-size: 20px;
          font-family: 'Roboto-Light';
          & span{
            font-family: 'Exo-Bold';
          }
        }
      }
    }
  }
  &-footer{
    &__title{
      font-size: 32px;
      font-family: 'Exo-Light';
      color: #404b6e;
      line-height: 48px;
      margin-bottom: 80px;
    }
    &-list{
      display: flex;
      justify-content: space-between;
      @include laptop{
        flex-wrap: wrap;
        justify-content: flex-start
      }
    }
    &-item{
      flex: 0 1 261px;
      @include laptop{
        margin-right: 20px;
      }
      &__title{
        font-size: 26px;
        color: #333333;
        font-family: 'Exo-Bold';
        margin-bottom: 35px;
      }
      &__img{
        content: '';
        width: 100%;
        height: 230px;
        background-size: cover!important;
        margin-bottom: 30px;
      }
      &-buts{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__link{
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: 'Exo-Light';
        margin-bottom: 25px;
        color: #333333;
        & img{
          margin-right: 10px;
        }
        & span{
          border-bottom: 1px dashed #333;
        }
      }
    }
  }
  &-bot{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 115px;
    @include laptop{
      flex-wrap: wrap;
      justify-content: center;
    }
    @include tablets{
      margin-top: 30px;
    }
    &-item{
      flex: 0 1 570px;
      &--bg{
        box-sizing: border-box;
        padding: 60px;
        background: #ececec;
        @include laptop{
          margin-top: 65px;
        }
        @include tablets{
          padding: 50px 20px;
        }
      }
      &__title{
        font-size: 32px;
        color: #404b6e;
        font-family: 'Exo-Light';
        margin-bottom: 40px;
        @include tablets{
          font-size: 26px;
        }
      }
      &__desc{
        font-size: 20px;
        font-family: 'Roboto-Light';
        color: #333;
        line-height: 30px;
        & b{
          font-family: 'Exo-Bold';
        }
      }
    }
  }
  &__last{
    font-size: 22px;
    color: #333;
    font-family: 'Roboto-Light';
    text-align: center;
    margin: 90px 0;
    @include tablets{
      margin: 30px 0;
    }
  }
}
.block-form{
  background: url(../images/main-bg.jpg) center center no-repeat;
  padding: 140px 0 170px;
  @include laptop{
    padding: 85px 0 120px;
  }
  @include tablets{
    padding: 70px 0;
    background-size: cover;
  }
  &-content{
    max-width: 830px;
  }
  &__title{
    color: #fff;
    font-family: 'Exo-Bold';
    font-size: 58px;
    margin-bottom: 50px;
    @include laptop{
      font-size: 48px;
      margin-bottom: 45px;
    }
    @include tablets{
      font-size: 26px;
    }
  }
  &__text{
    font-size: 32px;
    color: #fff;
    font-family: 'Roboto-Light';
    margin-bottom: 80px;
    @include laptop{
      font-size: 26px;
      margin-bottom: 55px;
    }
  }
  &-main{
    max-width: 360px;
    background: #fff;
    padding: 55px 65px;
    @include tablets{
      padding: 30px 10px;
    }
    & .form__title{
      font-size: 20px;
    }
    & .form-calc{
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      & span{
        margin: 0 20px;
        font-family: 'Roboto-Light';
      }
    }
  }
}
.reviews{
  padding-top: 120px;
  margin-bottom: 130px;
  @include tablets{
    padding-top: 65px;
  }
  &__title{
    color: #232323;
    font-family: 'Exo-Bold';
    font-size: 58px;
    line-height: 65px;
    margin-bottom: 90px;
    @include laptop{
      font-size: 48px;
      margin-bottom: 45px;
    }
    @include tablets{
      font-size: 26px;
      line-height: 30px;
    }
  }
  &-slider{

    &-wrap{
      position: relative;
      margin-bottom: 110px;
      @include tablets{
        margin-bottom: 90px;
      }
    }
    &-item{
      &-inn{
        display: flex;
        align-items: center;
        @include laptop{
          align-items: flex-start;
        }
        @include tablets{
          flex-direction: column;
          align-items: center;
        }
      }
      &__img{
        margin: 0 80px;
        width: 270px;
        height: 270px;
        content: '';
        background-size: cover;
        border-radius: 50%;
        display: block;
        @include laptop{
          width: 210px;
          height: 210px;
        }
        @include tablets{
          margin-bottom: 35px;
        }
      }
      &__text{
        max-width: 552px;
        font-size: 24px;
        color: #333;
        font-family: 'Exo-LightItalic';
        @include laptop{
          max-width: 300px;
        }
        @include tablets{
          max-width: 100%;
          font-size: 18px;
        }
      }
    }
    &-controls{
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 70px;
      &-next{
        &__img{
          transform: rotate(180deg);
        }
      }
    }
  }
  &-bot{
    display: flex;
    justify-content: space-between;
    @include laptop{
      flex-wrap: wrap;
    }
    &-left{
      max-width: 570px;
      &__title{
        font-size: 58px;
        font-family: 'Exo-Bold';
        color: #232323;
        margin-bottom: 60px;
        @include laptop{
          font-size: 48px;
          margin-bottom: 45px;
        }
        @include tablets{
          font-size: 26px;
          margin-bottom: 25px;
        }
      }
      &__text{
        font-size: 20px;
        font-family: 'Roboto-Light';
        color: #232323;
        margin-bottom: 60px;
      }
      &-list{
        &-item{
          display: flex;
          align-items: flex-start;
          margin-bottom: 60px;
          &__img{
            margin-right: 40px;
          }
          &__text{
            font-size: 20px;
            font-family: 'Roboto-Light';
            color: #232323;
          }
        }
      }
    }
    &-form{
      max-width: 450px;
      background: #ececec;
      padding: 60px 50px;
      box-sizing: border-box;
      @include tablets{
        padding: 50px 20px;
      }
      & .form-main__input{
        background: #fff;
      }
      & input[type=checkbox] + label:before {
        background: #fff;
      }
    }
  }
}
.contacts{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ececec;
  @include laptop{
    flex-wrap: wrap;
    padding-top: 90px;
  }
  @include tablets{
    padding-top: 70px;
  }
  &-left{
    flex: 0 1 50%;
    display: flex;
    justify-content: flex-end;
    @include laptop{
      flex: 0 1 100%;
      margin-bottom: 120px;
    }
    @include tablets{
      margin-bottom: 85px;
    }
    &-inn{
      flex: 0 1 530px;
      @include laptop{
        flex:auto;
        max-width: 95%;
        margin: 0 auto;

      }
    }
  }
  &__title{
    font-size: 58px;
    color: #232323;
    font-family: 'Exo-Bold';
    margin-bottom: 60px;
    @include laptop{
      font-size: 48px;
      margin-bottom: 45px;
      & br{
        display: none;
      }
    }
    @include tablets{
      font-size: 26px;
      margin-bottom: 35px;
    }
  }
  &__text{
    font-size: 20px;
    color: #232323;
    font-family: 'Roboto-Light';
    margin-bottom: 60px;
  }
  &__addres{
    font-size: 20px;
    color: #232323;
    font-family: 'Roboto-Light';
    & p {
      font-family: 'Exo-Bold';
    }
  }
  &-map{
    flex: 0 1 50%;
    min-height: 860px;
    @include laptop{
      flex: 0 1 100%;
      height: 550px;
      min-height: auto;
      & > ymaps{
        height: 550px!important;
      }
    }
  }
}
.work{
  padding: 140px 0;
  @include tablets{
    padding: 70px 0;
  }
  &__title{
    font-size: 58px;
    font-family: 'Exo-Bold';
    color: #232323;
    margin-bottom: 100px;
    @include laptop{
      font-size: 48px;
      margin-bottom: 60px;
    }
    @include tablets{
      font-size: 26px;
      margin-bottom: 50px;

    }
  }
  &-list{
    display: flex;
    justify-content: space-between;
    margin-bottom: 130px;
    @include laptop{
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    &-item{
      flex: 0 1 517px;
      display: flex;
      flex-direction: column;
      @include laptop{
        margin-bottom: 90px;
      }
      @include tablets{
        margin-bottom: 50px;
      }
      &__title{
        color: #414c6f;
        font-size: 32px;
        font-family: 'Exo-Light';
        margin-bottom: 55px;
        @include tablets{
          font-size:26px;
          margin-bottom: 30px;
          & br{
            display: none;
          }
        }
      }
      &__text{
        font-size: 20px;
        font-family: 'Roboto-Light';
        margin-bottom: 55px;
      }
      &__but{
        font-family: 'Exo-Bold';
        font-size: 20px;
        color: #232323;
        display: flex;
        align-items: center;
        margin-top: auto;
        & span{
          border-bottom: 1px dashed #232323;
          padding-bottom: 10px;
          margin-right: 20px;
        }
      }
    }
  }
  &-bot{
    &-list{
      display: flex;
      justify-content: space-between;
      @include tablets{
        flex-wrap: wrap;
      }
    }
    &-item{
      flex: 0 1 278px;
      @include tablets{
        flex: 0 1 100%;
        margin-bottom: 35px;
      }
      &:last-child{
        & .work-bot-item__number:after{
          display: none;
        }
      }
      &__number{
        color: #e4e8ea;
        font-family: 'Exo-Bold';
        font-size: 68px;
        line-height: 33px;
        margin-bottom: 40px;
        position: relative;
        @include tablets{
          margin-bottom: 20px;
        }
        &:after{
          width: 106px;
          height: 10px;
          content: '';
          display: block;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          @include laptop{
            width: 75px;
            background: url('../images/icons/arrow-right.png') center right no-repeat;
          }
        }
        @include laptop{
          font-size: 50px;
        }
      }
      &__text{
        font-size: 20px;
        color: #232323;
        font-family: 'Roboto-Light';
        line-height: 30px;
        @include tablets{
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }
}
.pre-footer{
  padding: 160px 0;
  background: url(../images/main-bg.jpg) center center no-repeat;
  background-size: cover;
  @include tablets{
    padding: 80px 0;
  }
  & .container{
    display: flex;
    align-items: flex-end;
    @include tablets{
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  &-left{
    flex: 0 1 570px;
    @include tablets{
      margin-top: 80px;
      flex: auto;
    }
    &__title{
      font-size: 26px;
      font-family: 'Exo-Light';
      color: #fff;
      margin-bottom: 30px;
      @include laptop {
        font-size: 18px;
      }
      @include tablets{
        margin-bottom: 20px;
      }
    }
    &__phone{
      font-size: 46px;
      font-family: 'Exo-Bold';
      color: #fff;
      @include laptop{
        font-size: 29px;
      }
    }
  }
  &-right{
    flex: 0 1 570px;
    &__title{
      font-size: 58px;
      color: #fff;
      font-family: 'Exo-Bold';
      margin-bottom: 60px;
      @include laptop{
        font-size: 48px;
      }
      @include tablets{
        font-size: 26px;
        margin-bottom: 35px;
      }
    }
    &__text{
      font-size: 32px;
      color: #def1ff;
      line-height: 44px;
      font-family: 'Roboto-Light';
      margin-bottom: 90px;
      @include laptop{
        font-size: 26px;
      }
      @include tablets{
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 55px;
      }
    }
    &-form{
      max-width: 360px;
      &__title{
        font-size: 26px;
        color: #fff;
        font-family: 'Exo-Bold';
        margin-bottom: 50px;
      }
      & .form-main__label{
        & span{
          font-size: 15px;
          font-family: 'Roboto-Light';
          color: #fff;
        }
        & a{
          font-size: 15px;
          color: #fff;
        }
      }
    }
  }
}
.footer{
  padding: 60px 0;
  @include laptop{
    padding: 60px 0 30px;
  }
  &-top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: flex-end;
    @include laptop{
      flex-wrap: wrap;
    }
    @include tablets{
      flex-direction: column;
      align-items: center;
    }
  }
  &__logo{
    font-size: 36px;
    color: #052451;
    font-family: 'Exo-Bold';
    @include laptop{
      flex: 0 1 50%;
    }
    @include tablets{
      flex: auto;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  &__police{
    font-family: 'Exo-Light';
    font-size: 20px;
    color: #333333;
    text-decoration: underline;
    @include laptop{
      flex: 0 1 50%;
    }
  }
  &-link{
    font-family: 'Exo-Light';
    font-size: 20px;
    color: #333;
    @include laptop{
      flex: 0 1 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;

    }
    & a{
      font-size: 20px;
      color: #0d95e8;
      text-decoration: underline;
    }
  }
  &-bot{
    font-family: 'Exo-Light';
    font-size: 20px;
    color: #333;
    text-align: center;
  }
}