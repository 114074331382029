.form{
  &__title{
    font-size: 26px;
    font-family: 'Exo-Bold';
    line-height: 30px;
    color: #22262b;
    margin-bottom: 25px;
    @include tablets{
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
      & br{
        display: none;
      }
    }
  }
  &__text{
    font-size: 20px;
    font-family: 'Roboto-Light';
    color: #22262b;
    line-height: 27px;
    margin-bottom: 30px;
    @include tablets{
      font-size: 18px;
    }
  }
  &-main{
    &__input{
      background: #ececec;
      border: none;
      color: #22262b;
      padding: 30px 35px;
      box-sizing: border-box;
      width: 100%;
      font-family: 'Roboto-Light';
      margin-bottom: 10px;
      @include tablets{
        padding: 25px 30px;
        font-size: 16px;
      }
    }
    &__submit{
      width: 100%;
      height: 81px;
      font-family: 'Exo-Bold';
      font-size: 26px;
      color: #22262b;
      background: #f4b052;
      border: none;
      border-bottom: 4px solid #d89840;
      cursor: pointer;
      @include tablets{
        font-size: 18px;
        height: 61px;
      }
    }
    &-footer{
      margin-top: 22px;
      position: relative;
    }
    &__label{
      font-family: 'Roboto-Light';
      color: #22262b;
      font-size: 15px;
      & span{
        opacity: .7;
        @include tablets{
          display: inline-block;
          width: 200px;
        }
      }
      & a{
        text-decoration: underline;
        color: #22262b;
        opacity: .7;
        transition: all .3s;
        font-size: 15px;
        @include tablets{
          margin-left: 30px;
        }
        &:hover{
          opacity: 1;
        }
      }
    }
  }
  &-block{
    flex: 0 1 50%;
    margin-bottom: 20px;
    & label{
      font-size: 18px;
      color: #333;
      font-family: 'Roboto-Light';
    }
  }
  &-top{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;
  }
}
input[type=checkbox] + label:before {
  content: "\2713";
  color: transparent;
  display: inline-block;
  border: none;
  font-size: 20px;
  line-height: 22px;
  margin: -5px 5px 0 0;
  background: #ececec;
  height: 24px;
  width: 24px;
  text-align: center;
  vertical-align: middle;
  transition: color ease .3s;
}
input[type=checkbox], input[type=radio] {display: none;}
input[type=checkbox]:checked + label:before {
  color: #000;
}

.cls-1 {
  fill: none;
  stroke: #f7f4f4;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}

.cls-2 {
  fill: #ecebeb;
  &.active{
    fill: #f8ae59;
  }
}

.cls-3 {
  fill: #fff;
}

.cls-4 {

}