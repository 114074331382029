//variables
$blue: #052451;
$blueSmall: #134999;
$nebo: #def1ff;
$black: #333333;
$orange: #f4b052;

.html,body{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .wrapper{
    //min-width: 1200px;
    overflow: hidden;
  }
  p{
    margin: 0;
    bottom: 0;
  }
  a{
    text-decoration: none;
  }
  ul{
    padding: 0;
    margin: 0;
  }
  ul li{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  .wrapper{
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    height: 100%;
  }
  .footer{
    //margin-top: -133px;
  }
  .container{
    margin: 0 auto;
    position: relative;
    max-width: 1170px;
    width: 95%;
  }
  html{
    color: $black;
  }
  .typed-cursor{
    display: none;
  }
  h1{
    margin: 0;
    padding: 0;
  }
  *:focus{
    outline: none;
  }


  